import Layout from "../../components/layouts/layout";
import {useQuery} from "../../hooks/useQuery";
import {changePassword, getProfile} from "./auth";
import {Badge, Breadcrumb, Button, Col, Container, Form, Table} from "react-bootstrap";
import {DatePicker, Modal, Placeholder} from "rsuite";
import {useLang} from "../../hooks";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {InputValidationError} from "../../libs/error";
import { Input, InputGroup } from 'rsuite';
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {useNotification} from "../../hooks/useNotification";


export default function UserProfile() {
  const {t} =  useLang()
  const {data: user, error, loading} = useQuery(getProfile, );
  const [showPasswordEdit, setShowPasswordEdit] = useState(false)


  return <>
  {showPasswordEdit && <ChangePasswordModal open={true} onClose={() => setShowPasswordEdit(false)} />}
    <Layout>
      <Breadcrumb className="d-flex align-items-center">
        <Breadcrumb.Item active>
          {t("profile")}
        </Breadcrumb.Item>
      </Breadcrumb>
      {loading && <Placeholder.Graph active={true} />}
      {
        !loading && user && <div>
          <div className="d-flex gap-2 flex-wrap align-items-center">
            <h1>{user?.name}</h1>
            <Button size={"sm"} className="ml-auto" onClick={() => setShowPasswordEdit(true)}>Change Password</Button>
          </div>
          {user.roles && <div className="mt-3">
            {user.roles?.map(r => <Badge className="m-1">{r?.name}</Badge>)}
          </div>}
          <Table className="mt-4">
            <tr>
              <td>Email</td>
              <td>{user?.name}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{user.phone}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{user.gender}</td>
            </tr>
          </Table>
        </div>
      }
    </Layout>
  </>;
}

export function ChangePasswordModal({open, onClose}) {
  const [visible, setVisible] = useState(false);
  const {t} = useLang()
  const {
    formState: {errors},
    setError,
    handleSubmit,
    register,
    control}
    = useForm({resolver: yupResolver(yup.object({
      password: yup.string().required().label(t("password")),
      confirm_password: yup.string().oneOf([yup.ref("password")], "Password must match")
        .required().label(t("retype_password")),
    }))})
  const {notifyError, notifySuccess} = useNotification();

  const onPasswordSubmit = async (data) => {
    try {
      await changePassword(data)
      onClose()
      notifySuccess({
        title: "Password Change Successful!",
      });
    } catch(err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError)
      }
      notifyError({
        title: "Error!",
        message: err.message,
      });
    }
  }

  return <Modal open={open} onClose={onClose}>
    <Modal.Header>
      <Modal.Title>{t('change-password')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Container>
        <Form onSubmit={handleSubmit(onPasswordSubmit)}>
          <div className="d-flex flex-column gap-2">
            <Form.Group as={Col} controlId="password">
              <Form.Label className="mark-required">{t("password")}</Form.Label>
              <Controller
                control={control}
                name="password"
                render={({field}) => (
                  <InputGroup inside>
                    <Input
                      {...field}
                      placeholder={t("password_phld")}
                      type={visible ? 'text' : 'password'} />
                    <InputGroup.Button onClick={()=> setVisible(!visible)}>
                      {visible ? <FaEye /> : <FaEyeSlash />}
                    </InputGroup.Button>
                  </InputGroup>
                )}
              />
              {errors.password?.message && (
                <Form.Text className="text-danger">
                  {errors.password.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="confirm_password">
              <Form.Label className="mark-required">{t("retype_password")}</Form.Label>
              <Controller
                control={control}
                name="confirm_password"
                render={({field}) => (
                  <InputGroup inside>
                    <Input
                      {...field}
                      placeholder={t("password_phld")}
                      type={visible ? 'text' : 'password'} />
                    <InputGroup.Button onClick={()=> setVisible(!visible)}>
                      {visible ? <FaEye /> : <FaEyeSlash />}
                    </InputGroup.Button>
                  </InputGroup>
                )}
              />
              {errors.confirm_password?.message && (
                <Form.Text className="text-danger">
                  {errors.confirm_password.message}
                </Form.Text>
              )}
            </Form.Group>

          <div className="d-flex gap-2">
            <Button type="submit" className="ml-auto" variant="primary">
              Change
            </Button>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
          </div>
          </div>
        </Form>
      </Container>
    </Modal.Body>
  </Modal>
}
