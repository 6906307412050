import {api} from "../../services/api";

export async function getPaginatedBRACForm(options) {
  const r = await api.get("/brac_forms/form-list", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}