import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {DatePicker, SelectPicker, TagInput, Toggle} from "rsuite";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import {TextEdit} from "../../components";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {useLang, useTermList} from "../../hooks";
import {PostInputSchema} from "./schema.js";
import {usePost} from "../../hooks/usePost";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {BackButton} from "../../components/BackButton/BackButton";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import {usePageList} from "../../hooks/usePageList";

export function PostEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const {post, editPost, handleMediaRemove, isLoading, error} = usePost(id);
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading,
  } = useTermList(1, {limit: 1000, page: 1});
  const {pageList, isLoading: pageLoading, error: pageErr}
    = usePageList(null, {page: 1, limit: 100});

  if (termsError) {
    notifyError(termsError);
    navigate("/posts/manage");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(PostInputSchema(t)),
  });

  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
    handleRemove,
  } = useMediaSelector();

  const name = watch("name");
  const isShow = watch("is_show");

  const handleMediaAddClick = async () => {

  };

  const handleServerRemove = async (media_id) => {
    try {
      await handleMediaRemove(media_id);
      notifySuccess({
        title: `Media Removed successfully`
      });
      setCheckValue(checkValue.filter(m => m!== media_id))
      setSelectedMedia(selectedMedia.filter(m => m.id !== media_id))
      setAllMediaId(allMediaId.filter(m => m!==media_id));
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/posts/manage"), 500);
  }

  useEffect(() => {
    if (post && termList.terms.length > 0) {
      setValue("name", post?.name ?? "");
      setValue("description", post?.description ?? "");
      setValue("post_category_id", post?.post_category?.id ?? -1);
      setValue("is_show", post?.is_show === 1);
      setValue("meta_title", post?.meta_title ?? "");
      setValue("meta_tag", post?.meta_tag?.split(",") ?? []);
      setValue("meta_description", post?.meta_description ?? "");
      setValue("page_id", post?.page_id ?? "");

      setValue(
        "post_date",
        post.post_date ? new Date(post.post_date) : undefined
      );

      let mediaIds = post?.files.map(m => m.media_id);
      let media = post?.files.map(m => m.media_content);

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [post, termList, setValue]);

  const handleEdit = async (data) => {
    try {
      const postData = { ...data, media_id: checkValue};
      await editPost(postData);
      notifySuccess({
        title: `Post "${post.name}" updated successfully`,
      });
      navigate("/posts/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update post!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isTermsLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/posts/manage`}> {t("posts_manage_posts")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("posts_edit_edit")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("posts_manage_posts")}/>
        </div>
      </header>
      <section id="#post-edit">
        <h2>{t("posts_edit_edit")} / {name}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(handleEdit)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={2} controlId="post_category_id">
            <Form.Label className="mark-required">{t("posts_edit_category")}</Form.Label>
            <Form.Control {...register("post_category_id")} as="select" custom>
              <option value={-1}>None</option>
              {termList.terms.map((trm) => (
                <option key={trm.id} value={trm.id}>
                  {trm.name}
                </option>
              ))}
            </Form.Control>
            {errors.post_category_id?.message && (
              <Form.Text className="text-danger">
                {errors.post_category_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="name">
            <Form.Label className="mark-required">{t("posts_edit_name")}</Form.Label>
            <Form.Control
              {...register("name")}
              placeholder={t("posts_edit_name_plhd")}
              type="text"
            />
            {errors.name?.message && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} lg={2} controlId="post_date">
            <Form.Label className="mark-required">{t("posts_edit_postDate")}</Form.Label>
            <Controller
              control={control}
              name="post_date"
              render={({field:{onChange, value, ref, disabled}}) => (
                <div tabIndex={-1} ref={ref}>
                  <DatePicker value={value} onChange={onChange} format="dd-MM-yyyy"/>
                </div>
              )}
            />
            {errors.post_date?.message && (
              <Form.Text className="text-danger">
                {errors.post_date.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="page_id">
            <Form.Label>{t("menu_content_page")}</Form.Label>
            <Controller control={control} render={
              ({field}) =>
                <SelectPicker onChange={field.onChange} value={field.value} data={pageList?.pages.map(p => ({
                  label: p.title,
                  value: p.id
                }))} style={{width: "100%"}} />
            } name="page_id" />

            {errors?.page_id?.message && (
              <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="is_show">
            <Form.Label className="mark-required">{t("posts_edit_visibility")}</Form.Label>
            <Controller
              control={control}
              name="is_show"
              render={({field: {value, ...field}}) => {
                return (
                  <div>
                    <Toggle
                      {...field}
                      checked={isShow}
                      size="lg"
                      checkedChildren={<span>Public</span>}
                      unCheckedChildren={<span>Private</span>}
                      className="m-auto"
                    />
                  </div>
                );
              }}
            />
            {(errors.is_show?.message || Array.isArray(errors.meta_tag)) && (
              <Form.Text className="text-danger">
                {errors?.is_show?.message ??
                  errors.is_show.find(Boolean).message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <Form.Group controlId="image">
          <Form.Label>{t("posts_edit_image")}</Form.Label>
          <div className="w-100">
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}
                            handleMediaAddClick={handleMediaAddClick}>
                  {
                    selectedMedia?.length > 0 ? (
                      <MediaCardList selectedMedia={selectedMedia} isDeleteServer handleServerRemove={handleServerRemove}/>
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </div>
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label className="mark-required">{t("posts_edit_description")}</Form.Label>
          <Controller
            control={control}
            name="description"
            render={({field}) => (
              <TextEdit defaultValue={post?.description} {...field} />
            )}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>

        <article id="seo" className="mt-4">

          <h3>{t("posts_edit_seo")}</h3>

          <div className="mt-2 d-flex gap-2 flex-column">
            <Form.Group controlId="metaTitle">
              <Form.Label >{t("posts_edit_metaTitle")}</Form.Label>
              <Form.Control
                {...register("meta_title")}
                placeholder={t("posts_edit_metaTitle_plhd")}
                type="text"
              />
              {errors.meta_title?.message && (
                <Form.Text className="text-danger">
                  {errors.meta_title.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="metaTags">
              <Form.Label >{t("posts_edit_metaTags")}</Form.Label>
              <Controller
                control={control}
                name="meta_tag"
                render={({field: {onChange, value, onBlur, disabled, name}}) => (
                  <TagInput
                    onChange={onChange}
                    name={name}
                    onBlur={onBlur}
                    disabled={disabled}
                    value={value}
                    placeholder={t("posts_edit_metaTags_plhd")}
                    style={{borderColor: "#ced4da"}}
                    className="d-block"
                  />
                )}
              />
              {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                <Form.Text className="text-danger">
                  {errors?.meta_tag?.message ??
                    errors.meta_tag.find(Boolean).message}
                </Form.Text>
              )}
            </Form.Group>


            <Form.Group controlId="metaDescription">
              <Form.Label>{t("posts_edit_metaDescription")}</Form.Label>
              <Form.Control
                {...register("meta_description")}
                as="textarea"
                rows={3}
                placeholder={t("posts_edit_metaDescription_plhd")}
              />
              {errors.meta_description?.message && (
                <Form.Text className="text-danger">
                  {errors.meta_description.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
        </article>

        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("posts_edit_save")}
          </Button>
          <Link className="btn btn-danger" to={`/posts/manage`}>
            {t("posts_edit_cancel")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
