import { Button } from "react-bootstrap";
import "./index.css";

export function FloatingButton({ icon, name, onClick }) {
  return (
    <Button onClick={onClick} className="btn py-2 px-2 shadow btn-floating">
      <div className="d-flex gap-2 align-items-center">
        {icon && icon} {name && <div>{name}</div>}
      </div>
    </Button>
  );
}
